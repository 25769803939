import { Admin, AppBar, defaultTheme, Layout, Resource } from 'react-admin';
import simpleRestProvider from 'ra-data-simple-rest';
import { LandingPage } from './landingPage';
import LoginIcon from '@mui/icons-material/Login';
import { useEffect } from 'react';
import kredit_opt_logo from './kredit_opt_logo.png';

const dataProvider = simpleRestProvider('/api/triangulation');
const tableHeaderBorderColor = '#DCDFEA';
const typography = {
  fontFamily: "'Inter', sans-serif",
  fontSize: 14, // Should be a number in pixels
  fontStyle: 'normal',
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,
};
const theme = {
  ...defaultTheme,
  sidebar: {
    width: 0, // The default value is 240
    closedWidth: 0, // The default value is 55
  },
  palette: {
    primary: {
      main: '#00A990',
    },
    background: {
      default: 'rgb(250, 250, 250)',
    },
  },
  components: {
    ...defaultTheme.components,
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgb(255, 255, 255)',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          border: '1px solid',
          borderColor: tableHeaderBorderColor,
          boxShadow: 'none',
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid',
          borderColor: tableHeaderBorderColor,
        },
        title: {
          fontWeight: '600',
          fontSize: '20px',
          lineHeight: '30px',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '0px !important',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          backgroundColor: '#007F6C',
          border: '1px solid #FECDCA',
          color: 'white',
          height: '40px',
          lineHeight: '20px',
          fontWeight: '500',
          fontSize: '14px',
          padding: '8px 14px 8px 14px',
          boxShadow: 'none',
          ':hover': {
            backgroundColor: '#007F6C',
            opacity: '0.6',
            boxShadow: 'none',
          },
        },
      },
    },
  },
  typography: typography,
};

const MyAppBar = () => {
  return (
    <AppBar
      sx={{
        '& .RaAppBar-menuButton': {
          display: 'none',
        },
      }}
    >
      <img
        id="product_logo"
        alt="kredit optimization logo"
        height="20px"
        style={{ padding: '0px 12px' }}
        src={kredit_opt_logo}
      />
      <div
        id={'applicationVersion'}
        style={{
          textAlign: 'right',
          position: 'absolute',
          right: '8px',
          color: '#444',
        }}
      />
    </AppBar>
  );
};

const MyLayout = (props: any) => <Layout {...props} appBar={MyAppBar} />;

const App = () => {
  useEffect(() => {
    document.title = 'Kredit Optimisation';
  });

  return (
    <Admin
      layout={MyLayout}
      title="Kredit Optimisation"
      theme={theme}
      dataProvider={dataProvider}
    >
      <Resource
        name="appSettings"
        list={LandingPage}
        options={{ label: 'Login' }}
        icon={LoginIcon}
      />
    </Admin>
  );
};

export default App;
